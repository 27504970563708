(function() {
  var elem = document.querySelector(".contact-us");
  if (elem) {
    elem.addEventListener("click", function() {
      try {
        Intercom("show");
      } catch (err) {}
    });
  }
})();
